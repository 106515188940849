/* Color theme 1*/
:root {
    --navbarlink: #ccc;
    --navItems: #eee;
    --pageTitle: #eee;
    --footerBorder: #aaa;
    --titleSize: 40px;
    --footerColor: #232f5c;
    --bannerbtnback: #000;
    --bannerbtntext: #eee;
    --floatingEventTitle: #774800;
    --floatingEventTitle1: #f90
}

/*General Style Elements*/
.container {
    width: 1175px;
    margin: auto;
    padding: 15px;
}

.title_section {
    margin-top: 100px;
    text-align: center;
    color: var(--pageTitle);
    font-weight: 600;
    margin-bottom: 50px;

}

.title_section h4 {
    font-size: var(--titleSize);
}

.page_content {
    background-color: var(--pageTitle);
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    padding: 1rem;
    padding-top: 3rem;
}

.hide {
    display: none !important;
}

/* Navbar styles*/
.nav_header {
    background-image: linear-gradient(180deg, #000, #0000);
    position: absolute;
    z-index: 4;
    width: 100vw;
}

.nav_overlay {
    background-image: linear-gradient(180deg, #000, #0000);
    position: absolute;
    z-index: 4;
    width: 100vw;
    display: none;
}

.nav_overlay .nav_menu {
    background-color: #000e;
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 5;
    padding-left: 0;
    top: 0;
    font-size: 22px;
}

.nav_overlay .nav_menu_item {
    display: block;
    padding: 10px;
    width: 100%;

}

.nav_header .brand {
    width: 20%;
    height: 50px;
    display: inline-block;
}

.nav_header .brand_logo {
    width: 130px;
    margin: 20px;
    position: absolute;
}

.nav_overlay .brand_logo {
    height: inherit;
    margin-top: 10px;
    margin-left: 10px;
}

.nav_overlay .brand {
    height: 50px;
    display: inline-block;
}

#nav_bars {
    float: right;
    margin-top: 10px;
    width: 4rem;
}

#nav_bars button,
#nav_close button {
    border: 1px solid #fff;
    padding: 5px 12px;
    border-radius: 50%;
    font-size: 19px;
    margin-top: -5px;
}

ul.nav_menu {
    width: 70%;
    list-style: none;
    display: inline-block;
    margin: auto;
    text-align: right;
}

.nav_menu_item {
    display: inline-block;
    padding: 5px;
    width: 7rem;
    text-align: center;
    font-weight: 700;
    color: var(--navItems);
}

.login_link {
    position: relative;
    float: right;
    margin: 5px 10px;
}

a.nav_link {
    text-decoration: none;
    color: var(--navbarlink);
}

/*Home Banner Section*/
.home-section {
    width: 100vw;
    overflow: hidden;
    background: url('./assets/Banners.svg') fixed no-repeat;
    background-size: cover;

}

.banner {
    padding-top: 40px;
    width: 60%;
}

.banner-image {
    width: 100%;
}

.app-store-btn {
    background-color: var(--bannerbtnback);
    color: var(--bannerbtntext);
    border-radius: 1rem;
    padding: 1rem 4rem;
    margin: 0 2rem;
    display: inline-block;
    text-decoration: none;
}

.app-store-btn i {
    font-size: 30px;
    margin-right: 5px;
}

.app-store-btn span {
    font-size: 25px;
}

.floating_event {
    width: 10rem;
    position: fixed;
    z-index: 10;
    top: 30%;
    left: 2rem;
    text-align: center;
}

.floating_invitation {
    background: #fff;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    overflow: hidden;
    margin: auto;
}

.floating_invitation img {
    width: inherit;
}

.floating_event p {
    margin-bottom: 0;
    color: var(--bannerbtntext);
    font-size: 12px;
}

.floating_event_title {
    background-color: var(--floatingEventTitle1);
    color: var(--floatingEventTitle) !important;
    font-size: 15px !important;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
}


/*footer Section Css */
#footer {
    background-color: var(--footerColor);
}

#footer .col-md-3 {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

#footer h4 {
    color: var(--pageTitle);
}

.quick_links {
    list-style: none;
    padding-left: 0;
}

li.footer_item {
    border-bottom: 1px dashed var(--footerBorder);
    padding: 10px 0;
}

.copyright-text {
    text-align: center;
    color: var(--footerBorder);
}

.footer_logo {
    width: 100%;
}


/* Responsive Desiging*/
@media only screen and (max-width: 600px) {
    .container {
        width: 580px;
    }

    .nav_header {
        display: none;
    }

    .nav_overlay {
        display: block
    }

    .app-store-btn {
        background-color: var(--bannerbtnback);
        color: var(--bannerbtntext);
        border-radius: 1rem;
        padding: 1rem 4rem;
        margin: 0 2rem;
        display: inline-block;
        text-decoration: none;
    }

    .app-store-btn i {
        font-size: 30px;
        margin-right: 5px;
    }

    .app-store-btn span {
        font-size: 25px;
    }
}

@media only screen and (max-width: 320px) {
    .container {
        width: 260px;
    }

    .nav_header {
        display: none;
    }

    .nav_overlay {
        display: block
    }

    .app-store-btn {
        padding: 1rem;
        margin: 0.5rem 2rem;
        width: 10rem;
    }

    .app-store-btn i {
        font-size: 17px;
    }

    .app-store-btn span {
        font-size: 16px;
    }

}