    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    hr{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
    .mainPage{
        display: flex;
        width: 100vw;
        background-color: #4b4b4b2d;
    }
    
    .App{
        width: 85%;
        margin: 1% 0.5rem 1.8% 2%;
        padding: 1rem 3rem;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        overflow-y: auto;
    }
    
    .AddUser{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 3rem;
        border: 0.5px solid black;
    }
    
    .userTable{
        width: 100%;
    }
    
    .actionButton{
        margin: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .modal-content  {
        background-color: #eee;
        padding: 1rem;
        width: 50%;
        margin: auto;
        margin-bottom: 5rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    
    .modal-content form{
        display: flex;
        gap: 1em;
        flex-direction: column;
    }

    .submit{
        display: flex;
        justify-content: flex-end;
        width: 100%;

    }

    .modal-content form button{
        padding: 0.8rem 1rem;
        background-color: #1c2146b6;
        backdrop-filter: blur(5px);
        border: none;
        border-radius: 0.3rem;
        color: #eee;
        font-weight: 500;
    }

    .modal-content form label{
        display: flex;
    }

    /* input, textarea, select{
        margin-left: 2rem;
        border: #4b4b4b solid 1px;
        border-radius: 5px;
        padding: 0 10px;
    } */
    
    /* ------------login page------------ */
    
    .loginComponent{
        height: 100vh;
        width: 100%;
        background-color: #3b3b7c;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('./assets/loginBackground.png');
        background-size: cover;
    }
    
    .login{
        width: auto;
        padding: 5rem 10rem;
        border-radius: 0.5rem;
        background-color: #00000094;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
    
    .login h2{
        color: #eee;
        margin-bottom: 0.5rem;
    }
    
    .login form{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .login input{
        font-size: 1rem;
        border: #eee 0.5px solid;
        padding: 0.5rem 0.8rem;
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #fff;
    }
    
    .login input:focus{
        outline: none;
    }
    
    .login input::placeholder{
        color: #aaa;
        font-size: 0.9rem;
    }
    
    .login .inputsGroup{
        display: flex;
        flex-direction: column;
        width: auto;
        align-items: center;
    }
    
    .login .inputsGroup .form-group{
        margin-bottom: 0.8rem;
    }
    
    .login form button{
        margin-top: 1rem;
        cursor: pointer;
        padding: 0.3rem 1.2rem;
        border-radius: 0.2rem;
        border: 0.5px #ffde22 solid;
        font-size: 1.2rem;
        font-weight: 600;
        color: #4b4b4b;
        background-color: #ffde22;
        transition: 0.5s;
    }
    
    .login form button:hover{
        background-color: #eeeeee3f;
        color: #eee;
        border: 0.5px #eee solid;
    }

    .login form p{
        color: #eee;
        font-size: 0.8rem;
        display: flex;
        margin-bottom: 0;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
    
    /* ----------side nav---------- */
    
    
    .sideNavComponent{
        min-width: 260px;
        max-width: 270px;
        padding: 1rem 0 1rem 1rem;
    }
    
    .sideNav{
        width: 100%;
        background-color: #1C2146;
        height: 100%;
        padding: 1rem;
        text-decoration: none;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    
    .sideNav .active{
        background-color: #2B2F57;
        color: #EFB016;
    }
    
    .sideNav .active p{
        scale: 1.05;
    }
    
    
    .sideNav .inactive{
        background-color: transparent;
        color: #eee;
    }
    
    .sideNav .inactive:hover{
        background-color: #2B2F57;
    }
    
    .sideNav .navLink {
        text-decoration: none;
        font-size: 1.2rem;
        padding: 0.5rem 0 0.5rem 1rem;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0.2rem 0rem;
        width: inherit;

    }
    
    .sideNav p{
        margin: 0;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    
    .userDetails{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        color: #eee;
    }
    
    .userData .userName{
        font-size: 1.8rem;
        font-weight: 500;
    }
    
    .logout{
        width: 100%;
        height: 2.5rem;
        margin-top: 2rem;
        border-radius: 0.2rem;
        bottom: 0;
        border: 1px solid #eee;
        background-color: #ffffff9a;
        backdrop-filter: blur(5px);
        color: #000;
        font-weight: 500;
        transition: 0.5s;
    }
    
    .sideNav hr{
        color: #fff;
    }
    
    .navlist{
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        width: 95%;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
    
    .logout:hover{
        backdrop-filter: blur(0px);
        background-color: #fff;
        font-weight: 600;
    }
    
    /* ---------------gallery------------- */
    
    .imageContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
        grid-gap: 1rem;
    }
    
    .imageWrapper {
        width: 10rem;
        height: 10rem;
        padding: 0.2rem;
        border: solid 0.5px #4b4b4b;
        border-radius: 0.5rem;
        background-color: #fff;
    }
    .imageWrapper_web {
        width: 17rem;
        height: 15rem;
        padding: 0.2rem;
        background-color: #fff;
        box-shadow: 2px 2px 2px #ccc;
    }
    .image_overlay{
        background-color: #000a;
        position: absolute;
        height: inherit;
        width: 17rem;
        z-index: 4;
    }
    
    .imageWithDeleteIcon {
        border-radius: 0.2rem;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .imageWrapper_web:hover .imageWithDeleteIcon {
        transform: scale(1.02);
        transition: transform 0.3s ease;
    }

    .loadMoreButton{
        border: 2px solid #4b4b4b;
        width: 17rem;
        height: 15rem;
        border-radius: 0.5rem;
        background-color: #ffff;
        font-weight: 600;
        font-size: 1.3rem;
        color: #4b4b4b;
        border-style: dashed;
    }
    
    .deleteButton {
        position: absolute;
        bottom: 0rem;
        right: 0rem;
        width: 2rem;
        height: 2rem;
        border-radius: 5px 0 0 0;
        background-color: #1c214681;
        border: none;
        cursor: pointer;
        backdrop-filter: blur(5px);
        transition: 0.5s;
    }
    
    .deleteButton:hover{
        backdrop-filter: blur(0px);
        background-color: #1C2146;
    }

    /* ----------------web users----------- */

    .web-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .web-header h1{
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #4b4b4b;
        margin-bottom: 0;
    }

    .web-header button{
        border: solid 1px #4b4b4b;
        background-color: #1c2146a1;
        backdrop-filter: blur(5px);
        padding: 0 1rem;
        font-size: 1rem;
        color: #eee;
        font-weight: 500;
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        transition: 0.5s;
    }

    .web-header button p{
        margin-bottom: 0;
    }

    .web-header button:hover{
        background-color: #1C2146;
        color: #fff;
        backdrop-filter: blur(0px);
    }

    .webUser-table {
        color: #4b4b4b;
        padding: 0;
        width: 100%;
        border-collapse:separate;
    }
    
    .webUser-table th,
    .webUser-table td {
        padding: 0.5rem 2rem;
        text-align: center;
    }
    
    .webUser-table th {
        background-color: #1c214693;
        backdrop-filter: blur(5px);
        color: #eee;
    }
    
    .webUser-table th,
    .webUser-table td {
        width: calc(100% / 5);
    }
    
    .web-actionButton {
        margin-right: 0.5rem; 
        border: none;
        height: 3rem;
        width: 3rem;
        padding: 0 1.5rem;
        background-color: #1c214693;
        backdrop-filter: blur(5px);
        border-radius: 0.2rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .actionBtnColumn{
        display: flex;
        gap: 0.5rem;
    }

    .events-table{
        color: #4b4b4b;
        padding: 0;
        width: 100%;
        border-collapse:separate;
    }

    .events-table th,
    .events-table td {
        padding: 0.5rem 1rem;
        text-align: center;
    }

    .events-table th {
        background-color: #1c214693;
        backdrop-filter: blur(5px);
        color: #eee;
    }
    
    .events-table th,
    .events-table td {
        width: calc(100% / 7);
    }

    .imageUserName{
        font-size: 0.8rem;
        display: flex;
        justify-content: space-between;
        width: 85%;
        padding: 0.2rem 0.5rem;
    }

    .imageUserName p{
        margin-bottom: 0;
    }


/* -----------popup form css----------- */

    .modelForm{
        margin-top: 1rem;
        padding-right: 5%;
    }

    .modelForm .formInputs{
        width: 100%;
        display: flex;
        margin-bottom: 2rem;
    }
    .modelForm .labels{
        width: 25%;
    }

    .modelForm .inputs{
        width: 75%;
    }

    .modelForm .labels, .modelForm .inputs{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .modelForm input, .modelForm textarea, .modelForm select{
        border: 0.5px solid #4b4b4b;
        border-radius: 0.3rem;
        padding: 0 0 0 5px;
        width: 100%;
    }

    .modelForm button{
        border: none;
        padding: 0.5rem 1.5rem;
        background-color: #1C2146;
        color: #eee;
        border-radius: 0.5rem;
    }

    .custom-file-upload {
        border: 2px dashed #4b4b4baf;
        padding: 5rem 3rem;
        border-radius: 1.5rem;
        cursor: pointer;
        width: 100%;
        margin: auto;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        color: #4b4b4b;
    }   
    
    .custom-file-upload input[type="file"] {
        display: none;
    }
    
    .custom-file-upload input[type="file"] + label::after {
        content: none !important;
    }

    .programPaperModal .ant-modal-content{
        /* max-width: 80%; */
        width: 60vw;
        height: 80vh;
    }

    .programPaperModal{
        margin: 0; /* Reset margin */
        transform: translateX(-50%); 
    }
    .about_img{
        width: 5rem;
    }